import { useMsal } from "@azure/msal-react";
import { useAccount } from "@racwa/react-adb2c";
import { useIsAuthenticated, useSessionIdStorageKey } from "raci-react-library";
import { useMemo } from "react";
import { ADB2C_CLIENT_ID } from "../../constants";
import { ClaimsMotorGlassApiClient } from "raci-claims-motor-glass-clientproxy";

let useApiClient: () => ClaimsMotorGlassApiClient;

const useRealApiClient = () => {
  const { instance } = useMsal();
  const adb2cAccount = useAccount({ clientId: ADB2C_CLIENT_ID });
  const isAuthenticated = useIsAuthenticated();
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useMemo(() => {
    const loginRequest = {
      account: adb2cAccount,
      scopes: [],
      redirectUri: process.env.REACT_APP_ADB2C_RETURN_URL,
    };

    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const headers: any = { ...init?.headers };
      const sessionId = sessionStorage.getItem(sessionIdStorageKey);
      if (sessionId) {
        headers.SessionId = sessionId;
      }

      let token = "";
      if (isAuthenticated) {
        try {
          const tokenResponse = await instance.acquireTokenSilent(loginRequest);
          token = tokenResponse.idToken;
        } catch {
          await instance.acquireTokenRedirect(loginRequest);
        }
      }

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new ClaimsMotorGlassApiClient(process.env.REACT_APP_API_BASE_URL || "", {
      fetch: fetchWrapper,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
};

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT"
) {
  // DEV/SIT require Racwa-Internal-Authorization header when accessed outside internal network
  useApiClient = require("../../../__NPE__/useApiClient").default;
} else if (process.env.REACT_APP_ENVIRONMENT === "UAT") {
  const { BypassService } = require("raci-react-library-npe");
  // Need to use NPE client to set RACWA-CRM-ID header if 'logged in' via test launch page
  useApiClient = BypassService.isUsingTestLaunchPage()
    ? require("../../../__NPE__/useApiClient").default
    : useRealApiClient;
} else {
  useApiClient = useRealApiClient;
}

export default useApiClient;
