import { setIntervalGuarded, useSessionIdStorageKey } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ERROR_SESSION_TIMEOUT_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";
import { ClaimsMotorGlassApiException } from "raci-claims-motor-glass-clientproxy";

export const HTTP_STATUS_CODE_TIMEOUT = 408;
export const EXPIRY_CHECK_INTERVAL = 30000;

export const useSessionExpiry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const [expireInSeconds, setExpireInSeconds] = useState<number | null>(null);
  const [wait, setWait] = useState(EXPIRY_CHECK_INTERVAL);
  const [isError, setIsError] = useState(false);

  const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL);

  useEffect(() => {
    let isCancelled = false;

    const checkSessionExpiry = async () => {
      try {
        setIsError(false);
        const response = await client.getSessionExpiryInSeconds();
        if (!isCancelled) {
          const expiry = parseInt(response.result);
          setWait(getNextDelay(expiry));
          setExpireInSeconds(expiry);
        }
      } catch (ex) {
        const response = ex as ClaimsMotorGlassApiException;
        if (response?.status === HTTP_STATUS_CODE_TIMEOUT) {
          setWait(getNextDelay(-1));
          setExpireInSeconds(-1);
          navigate(ERROR_SESSION_TIMEOUT_PAGE_URL, {
            replace: true,
            state: {
              referrer: location.pathname,
            },
          });
        } else {
          setIsError(true);
        }
      }
    };

    const isSessionTimeoutPage = location.pathname.endsWith(HTTP_STATUS_CODE_TIMEOUT.toString());

    if (!isSessionTimeoutPage) {
      const handle = setIntervalGuarded(async () => {
        if (sessionStorage.getItem(sessionIdStorageKey)) {
          await checkSessionExpiry();
        }
      }, wait);
      return () => {
        isCancelled = true;
        clearTimeout(handle);
      };
    }

    return () => {
      isCancelled = true;
    };
  }, [wait, client, navigate, location.pathname, sessionIdStorageKey]);

  return {
    isError,
    expireInSeconds,
  };
};

export default useSessionExpiry;
