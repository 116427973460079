import { Box, Button, Typography } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { theme } from "@racwa/react-components";
import {
  ErrorPageContent,
  InsurancePhoneNumber,
  PhoneButton,
  useClearSession,
  useGetSessionState,
} from "raci-react-library";
import { useEffect } from "react";
import useStandardPage from "../../shared/hooks/useStandardPage";
import { FormRoute } from "../../shared/routing/routes.config";
import { StartYourClaimState } from "../StartYourClaim/types";

export const SessionTimeout: React.FC = () => {
  const clearSession = useClearSession();
  const { claimNumber } = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const standardPageProps = useStandardPage();

  useEffect(() => {
    gtm(event(`Session timeout - previous page: ${document.referrer}`));
    clearSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const claimNumberLine = claimNumber ? (
    <Typography>
      Your claim number is{" "}
      <Box component="span" fontWeight="600" color={theme.palette.secondary.light}>
        {claimNumber}
      </Box>
    </Typography>
  ) : undefined;

  const subtitle = claimNumber ? "Please call us to continue your claim." : "Please try again.";
  const buttons = [
    claimNumber ? (
      <PhoneButton
        id="call-us-button"
        data-testid="call-us-button"
        isPrimary
        size="medium"
        phone={InsurancePhoneNumber}
      />
    ) : (
      <Button
        type="button"
        data-testid="sessiontimeout-button"
        color="primary"
        variant="contained"
        fullWidth
        href={process.env.REACT_APP_SPARK_TRIAGE_CAR_INSURANCE_URL}
      >
        Make a car insurance claim
      </Button>
    ),
  ];

  return (
    <ErrorPageContent
      title="It looks like your page timed out"
      subtitle={subtitle}
      extraContent={claimNumberLine}
      buttons={buttons}
    />
  );
};

export default SessionTimeout;
