import {
  ClaimsMotorGlassApiException,
  ContactDetails,
  ContactDetailsPhoneNumberType,
} from "raci-claims-motor-glass-clientproxy";
import { HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { STORAGE_KEY_POLICY_NUMBER } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, FormRoute, formRouteInfo } from "../../../../shared/routing/routes.config";
import { ContactDetailsFormProps, ContactDetailsState, MFEAction, PhoneNumberTypes } from "../../types";

export const useContactDetails = (): ContactDetailsFormProps => {
  const location = useLocation();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const [, setContactDetailsState] = useSessionState<ContactDetailsState>({
    skipPageTrackingRecalculation: true,
  });
  const apiClient = useApiClient();
  const [contactDetails, setContactDetails] = useState<ContactDetails>();
  const [isLoading, setIsLoading] = useState(true);

  const enableChangeMyDetails = process.env.REACT_APP_FEATURE_CHANGE_MY_DETAILS === "true";

  useEffect(() => {
    const getContactDetails = async () => {
      try {
        setBackdrop(true);
        const response = (await apiClient.getContactDetails()).result;
        setContactDetails(response);

        if (response.phoneNumberType === ContactDetailsPhoneNumberType.NoPhoneNumber) {
          handleError(null, true, "GET /contact-details");
        }
      } catch (exception) {
        const error = exception as ClaimsMotorGlassApiException;
        const enableChangeMyDetails = process.env.REACT_APP_FEATURE_CHANGE_MY_DETAILS === "true";
        const isMemberContactError = enableChangeMyDetails && error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE;

        handleError(exception, isMemberContactError, "GET /contact-details");
      } finally {
        setIsLoading(false);
        setBackdrop(false);
      }
    };

    const handleError = (e: unknown, isMemberContactError: boolean, requestPath: string) => {
      const error = e as ClaimsMotorGlassApiException;
      const status = error !== null ? error.status : "";

      const state = {
        referrer: location.pathname,
        exception: { request: requestPath, status },
        isMemberContactError: isMemberContactError,
      };

      navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
        state,
      });
    };

    if (enableChangeMyDetails) {
      getContactDetails();
    }
  }, []);

  const form = useForm<ContactDetailsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const onSubmit = async () => {
    setContactDetailsState({ isCompleted: true });
    if (sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER)) {
      navigate(formRouteInfo[FormRoute.StartYourClaim].path);
    } else {
      navigate(formRouteInfo[FormRoute.YourPolicy].path);
    }
  };

  const onError = async () => {
    navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL);
  };

  const onMFEAction = async (action: MFEAction) => {
    switch (action) {
      case MFEAction.Submit:
        onSubmit();
        break;
      case MFEAction.Error:
        onError();
        break;
      case MFEAction.TurnOffBackDrop:
        setBackdrop(false);
        break;
      case MFEAction.TurnOnBackDrop:
        setBackdrop(true);
        break;
    }
  };

  return {
    form,
    isLoading,
    firstName: contactDetails?.firstName,
    phoneType: `${contactDetails?.phoneNumberType}` as PhoneNumberTypes,
    maskedPhoneNumber: contactDetails?.phoneNumber,
    maskedEmail: contactDetails?.email,
    onSubmit,
    onMFEAction,
    enableChangeMyDetails,
  };
};

export default useContactDetails;
