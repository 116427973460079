import { faArrowUpRightFromSquare, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Link, Skeleton, Stack, Typography, styled } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { NotificationCard, PhoneNumber } from "raci-react-library";
import { SkeletonOrComponent } from "../../../../shared/components/SkeletonOrComponent";
import { StyledCard, StyledImg } from "./styled";

export type Repairer = "Novus Autoglass" | "O'Brien Autoglass" | "Instant Windscreens";

export interface RepairerCardProps {
  name: Repairer;
  phoneNumber: PhoneNumber;
  bookingLink?: string;
  enableIconWiggle?: boolean;
  showSkeletonDetails?: boolean;
  showSkeletonBookingAndNotification?: boolean;
}

interface RepairerDisplay {
  src: string;
  canSms: boolean;
}

const RepairerDisplayDetails: Record<Repairer, RepairerDisplay> = {
  "Novus Autoglass": {
    src: "novus.svg",
    canSms: false,
  },
  "O'Brien Autoglass": {
    src: "obrien.svg",
    canSms: true,
  },
  "Instant Windscreens": {
    src: "instant.jpeg",
    canSms: false,
  },
};

const ButtonWithSmallerIcon = styled(Button)({
  "*:first-of-type svg": {
    fontSize: "14px",
  },
});

export const RepairerCard: React.FC<RepairerCardProps> = ({
  phoneNumber,
  name,
  bookingLink,
  showSkeletonDetails = false,
  showSkeletonBookingAndNotification = false,
}) => {
  const repairerDisplayDetails = RepairerDisplayDetails[name];
  const getId = (id: string) => `repairer-card-${id}`;

  const bookingAndNotification = () => {
    const internal = (
      <>
        {bookingLink && (
          <Grid item xs>
            <ButtonWithSmallerIcon
              color="primary"
              variant="contained"
              type="button"
              role="button"
              id="book-repairs-online-button"
              data-testid="book-repairs-online-button"
              href={bookingLink}
              endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              onClick={() => gtm(event("Or book online"))}
            >
              Or book online
            </ButtonWithSmallerIcon>
          </Grid>
        )}
        <Grid item xs sx={{ textAlign: "left" }}>
          <NotificationCard
            icon={<FontAwesomeIcon icon={faLocationDot} />}
            message={{
              title: "They'll confirm your location",
              content: <Typography>Whether you're rural or metro, they'll fix your glass.</Typography>,
            }}
          />
        </Grid>
      </>
    );

    return showSkeletonBookingAndNotification ? (
      <Grid item xs>
        <Skeleton
          sx={{ borderRadius: "3px" }}
          variant="rectangular"
          animation="wave"
          width="100%"
          data-testid={getId("booking-and-notification-skeleton")}
        >
          {internal}
        </Skeleton>
      </Grid>
    ) : (
      internal
    );
  };

  return (
    <StyledCard data-testid="repairer-card">
      <Grid container direction="column" spacing={3}>
        <Grid item xs>
          <SkeletonOrComponent
            showSkeleton={showSkeletonDetails}
            variant="rectangular"
            sx={{ borderRadius: "3px" }}
            animation="wave"
            width="100%"
            data-testid={getId("details-title-skeleton")}
          >
            <Typography id={getId("title")} data-testid={getId("title")} variant="h3" component="h2">
              Your repairer is {name}
            </Typography>
          </SkeletonOrComponent>
        </Grid>
        <Grid item xs>
          <SkeletonOrComponent
            showSkeleton={showSkeletonDetails}
            sx={{ mx: "auto", borderRadius: "3px" }}
            variant="rectangular"
            animation="wave"
            data-testid={getId("details-logo-skeleton")}
          >
            <StyledImg repairerName={name} src={repairerDisplayDetails.src} alt={`Logo for ${name}`} />
          </SkeletonOrComponent>
        </Grid>
        <Grid item xs>
          <SkeletonOrComponent
            variant="rectangular"
            sx={{ borderRadius: "3px" }}
            animation="wave"
            width="100%"
            data-testid={getId("details-body-skeleton")}
            showSkeleton={showSkeletonDetails}
          >
            <Stack direction="column" spacing="1em">
              <Typography id={getId("body")} data-testid={getId("body")} variant="body1" whiteSpace="pre-line">
                They'll call you {repairerDisplayDetails.canSms && "or send an SMS"} within two business hours to
                arrange your repairs.
              </Typography>
              <Typography>
                Or you can call them on{" "}
                <Link data-testid={getId("phone-number-link")} href={phoneNumber.href} rel="noopener noreferrer">
                  {phoneNumber.display}
                </Link>{" "}
              </Typography>
            </Stack>
          </SkeletonOrComponent>
        </Grid>
        {bookingAndNotification()}
      </Grid>
    </StyledCard>
  );
};
