import { event, gtm } from "@racwa/analytics";
import {
  MultiChoiceOption,
  RadioButtonGroupItem,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GlassOptionNotRepaired,
  GlassOptionRepairBooked,
  GlassOptionRepaired,
} from "../../../../shared/constants/GlassOptions";
import useApiClient from "../../../../shared/hooks/useApiClient";
import { ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, FormRoute, formRouteInfo } from "../../../../shared/routing/routes.config";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { YourGlassRepairsFormProps, YourGlassRepairsState } from "../../types";
import { ClaimsMotorGlassApiException, GlassDamageOptions, UpdateGlassRepairDetailsRequest } from "raci-claims-motor-glass-clientproxy";

export const glassRepairOptions: RadioButtonGroupItem[] = [
  { id: GlassOptionNotRepaired.id, value: GlassOptionNotRepaired.id, label: GlassOptionNotRepaired.display },
  { id: GlassOptionRepairBooked.id, value: GlassOptionRepairBooked.id, label: GlassOptionRepairBooked.display },
  { id: GlassOptionRepaired.id, value: GlassOptionRepaired.id, label: GlassOptionRepaired.display },
];

export const glassDamageOptions: MultiChoiceOption[] = [
  { key: GlassDamageOptions.FrontWindscreen, label: "Front windscreen" },
  {
    key: GlassDamageOptions.Other,
    label: "Any other window glass",
    sublabel: "Includes rear, side windows and sunroof",
  },
];

const createRequest = (values: YourGlassRepairsState): UpdateGlassRepairDetailsRequest => ({
  isRepairOrganisedOrMade:
    values.glassRepairOption === GlassOptionRepairBooked.id || values.glassRepairOption === GlassOptionRepaired.id,
  glassDamageOptions:
    values.glassRepairOption === GlassOptionNotRepaired.id
      ? values.glassDamageOptions?.map((option) => option.key as GlassDamageOptions)
      : undefined,
});

export const useYourGlassRepairs = (): YourGlassRepairsFormProps => {
  const navigate = useNavigate();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useApiClient();
  const [currentYourGlassRepairsState, setYourGlassRepairsState] = useSessionState<YourGlassRepairsState>();
  const { claimNumber } = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);

  const onSubmit = async (newValues: YourGlassRepairsState) => {
    try {
      setBackdrop(true);
      const request = createRequest(newValues);
      await apiClient.updateGlassRepairDetails(request);
      setYourGlassRepairsState({
        ...newValues,
        glassDamageOptions:
          newValues.glassRepairOption === GlassOptionNotRepaired.id ? newValues.glassDamageOptions : undefined,
        isCompleted: true,
      });
      navigate(formRouteInfo[FormRoute.ReviewYourClaim].path);
    } catch (exception) {
      const error = exception as ClaimsMotorGlassApiException;
      navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
        state: {
          referrer: location.pathname,
          exception: { request: "POST /claims/glass-repair-details", status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  const recordGlassRepairOptionEvent = (option: string) => {
    switch (option) {
      case GlassOptionNotRepaired.id:
        gtm(event(GlassOptionNotRepaired.display));
        break;
      case GlassOptionRepairBooked.id:
        gtm(event(GlassOptionRepairBooked.display));
        break;
      case GlassOptionRepaired.id:
        gtm(event(GlassOptionRepaired.display));
        break;
      default:
        break;
    }
  };

  const form = useForm<YourGlassRepairsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: currentYourGlassRepairsState,
  });

  return {
    form,
    onSubmit,
    glassRepairOptions,
    glassDamageOptions,
    claimNumber: claimNumber ?? "",
    defaultDamageOption: currentYourGlassRepairsState.glassDamageOptions,
    recordGlassRepairOptionEvent,
  };
};

export default useYourGlassRepairs;
