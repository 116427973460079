import { SessionState } from "raci-react-library";
import { SubmitHandler, UseFormReturn } from "react-hook-form";

export enum MFEAction {
  Error = "Error",
  Submit = "Submit",
  TurnOnBackDrop = "TurnOnBackDrop",
  TurnOffBackDrop = "TurnOffBackDrop",
}

export type PhoneNumberTypes = "Mobile" | "Home" | "Work";

export interface ContactDetailsFormProps extends SessionState {
  form: UseFormReturn<ContactDetailsState>;
  isLoading: boolean;
  firstName?: string;
  phoneType: PhoneNumberTypes;
  maskedPhoneNumber?: string;
  maskedEmail?: string;
  onSubmit: SubmitHandler<ContactDetailsFormValues>;
  onMFEAction: (action: MFEAction) => Promise<void>;
  enableChangeMyDetails?: boolean;
}

export interface ContactDetailsFormValues {
  contactNumber?: string | undefined;
  email?: string | undefined;
}

export type ContactDetailsState = SessionState<ContactDetailsFormValues>;
