import { formatISO } from "date-fns";
import {
  ClaimsMotorGlassApiException,
  CreateClaimErrorResponse,
  CreateClaimErrorResponseIneligibleReason,
  CreateClaimRequest,
} from "raci-claims-motor-glass-clientproxy";
import {
  HTTP_STATUS_CODE_BAD_REQUEST,
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { STORAGE_KEY_POLICY_NUMBER } from "../../../../shared/constants";
import useApiClient from "../../../../shared/hooks/useApiClient";
import useFlowState from "../../../../shared/hooks/useFlowState";
import { ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, FormRoute, formRouteInfo } from "../../../../shared/routing/routes.config";
import { YourPolicyState } from "../../../YourPolicy/types";
import { StartYourClaimFormProps, StartYourClaimState } from "../../types";

const createRequest = (values: StartYourClaimState, allowSimilarClaim?: boolean): CreateClaimRequest => ({
  // JavaScript serialises this date incorrectly
  // their ISO format is a "simplified" version of the actual ISO format
  // @ts-expect-error
  eventDate: formatISO(values.date!),
  allowSimilarClaim,
});

export const useStartYourClaim = (): StartYourClaimFormProps => {
  const [currentStartYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>();
  const yourPolicyState = useGetSessionState<YourPolicyState>(FormRoute.YourPolicy);
  const navigate = useNavigate();
  const location = useLocation();
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const { flowStateChanged } = useFlowState();
  const isClaimCreated = !!currentStartYourClaimState.claimNumber;
  const claimNumber = currentStartYourClaimState.claimNumber;
  const isIneligibleForClaim =
    !!currentStartYourClaimState.error &&
    currentStartYourClaimState.error.ineligibleReason !== CreateClaimErrorResponseIneligibleReason.SimilarClaim;
  const [showSimilarClaimDialog, setShowSimilarClaimDialog] = useState(false);
  const [showClaimDetailsLockedCard, setShowClaimDetailsLockedCard] = useState(false);
  const [disabled, setDisabled] = useState(isClaimCreated || isIneligibleForClaim);

  const form = useForm<StartYourClaimState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      date: currentStartYourClaimState.date && new Date(currentStartYourClaimState.date),
    },
  });

  const policyNumber = sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER) ?? yourPolicyState.policyNumber;
  const policyDetailsAlreadyFetched = currentStartYourClaimState.policyDetails?.policyNumber === policyNumber;
  const policyDetails = policyDetailsAlreadyFetched ? currentStartYourClaimState.policyDetails : undefined;

  useEffect(() => {
    setShowClaimDetailsLockedCard(isClaimCreated);
    const getPolicyDetails = async () => {
      try {
        setBackdrop(true);
        if (!policyNumber) {
          throw new Error("Invalid Policy Number");
        }

        if (!policyDetailsAlreadyFetched) {
          const response = await apiClient.getPolicy(policyNumber);
          setStartYourClaimState({
            ...currentStartYourClaimState,
            policyDetails: { ...response.result, policyNumber },
          });
        }
      } catch (exception) {
        const error = exception as ClaimsMotorGlassApiException;

        const enableChangeMyDetails = process.env.REACT_APP_FEATURE_CHANGE_MY_DETAILS === "true";
        const isMemberContactError = enableChangeMyDetails && error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE;
        navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
          state: {
            referrer: location.pathname,
            exception: { request: `GET /policy`, status: error.status },
            isMemberContactError: isMemberContactError,
          },
        });
      } finally {
        setBackdrop(false);
      }
    };

    getPolicyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createClaim = async (newValues: StartYourClaimState, allowSimilarClaim?: boolean) => {
    try {
      setBackdrop(true);
      const request = createRequest(newValues, allowSimilarClaim);
      const response = await apiClient.createClaim(request);
      setStartYourClaimState({
        ...currentStartYourClaimState,
        ...newValues,
        ...response.result,
        isCompleted: true,
        error: undefined,
      });
      navigate(formRouteInfo[FormRoute.YourGlassRepairs].path);
    } catch (exception) {
      const error = exception as ClaimsMotorGlassApiException;
      if (error.status === HTTP_STATUS_CODE_BAD_REQUEST) {
        const result = error.result as CreateClaimErrorResponse;
        const responseState: StartYourClaimState = {
          ...newValues,
          ...currentStartYourClaimState,
          isCompleted: result.ineligibleReason !== CreateClaimErrorResponseIneligibleReason.SimilarClaim,
          error: {
            ineligibleReason: result.ineligibleReason,
            duplicateOrSimilarClaimNumber: result.duplicateOrSimilarClaimNumber,

            // the generated dto has the date as javascript Date, but the returned value from the API is actually a string
            // @ts-expect-error
            similarClaimEventDate: result.similarClaimEventDate,
          },
        };

        setStartYourClaimState(responseState);
        flowStateChanged && flowStateChanged();
        if (result.ineligibleReason === CreateClaimErrorResponseIneligibleReason.SimilarClaim) {
          setShowSimilarClaimDialog(true);
        } else {
          setDisabled(true);
        }
      } else {
        const enableChangeMyDetails = process.env.REACT_APP_FEATURE_CHANGE_MY_DETAILS === "true";
        const isMemberContactError = enableChangeMyDetails && error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE;
        navigate(ERROR_SYSTEM_UNAVAILABLE_PAGE_URL, {
          state: {
            referrer: location.pathname,
            exception: { request: "POST /claims/claim", status: error.status },
            isMemberContactError: isMemberContactError,
          },
        });
      }
    } finally {
      setBackdrop(false);
    }
  };

  const onSubmit = async (newValues: StartYourClaimState) => {
    if (!isClaimCreated) {
      return createClaim(newValues);
    }

    navigate(formRouteInfo[FormRoute.YourGlassRepairs].path);
  };

  const onSimilarClaimAllowed = async (newValues: StartYourClaimState) => {
    setShowSimilarClaimDialog(false);
    return createClaim(newValues, true);
  };

  return {
    form,
    onSubmit,
    policyDetails: policyDetails,
    error: currentStartYourClaimState.error,
    disabled,
    claimNumber,
    showSimilarClaimDialog,
    onSimilarClaimAllowed,
    showClaimDetailsLockedCard: showClaimDetailsLockedCard,
  };
};

export default useStartYourClaim;
